<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  /deep/.el-select.blueBorder .el-input__inner {
    border-color: rgb(23, 118, 210);
    height: 30px
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import PageHeader from "@/components/page-header";
  import {
    newsList,
    changeStatus,
    deleteNews,
    bigTypeList
  } from "@/api/web/news.js";
  import {
    easyList
  } from "@/api/admin/base/mainType.js"

  /**
   * 新闻公告列表
   */
  export default {
    components: {
      Layout,
      PageHeader,



    },
    data() {
      return {
        title: "新闻公告列表 ",
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "控制面板",
            active: true
          }
        ],
        query: {
          keyword: "",
          bigTypeId: "",
          lbbm: "",
          begDate: "",
          endDate: "",
          pageNum: 1,
          pageSize: 20,
          year: ""
        },
        checked: true,
        list: [],
        bigTypeList: [],
        classList: [],
        // 当前页
        currentPage: 1,
        // 每页显示
        perPage: 20,
        // 总数
        total: 0,
        years: []

      };
    },
    mounted() {
      this.getYear()
      this.getList();
      this.getBigTypeList();
      this.getClassListPage();
    },
    computed: {
      rows() {
        return this.list.length;
      },
    },
    methods: {
      /** 查询新闻列表 */
      getList() {
        this.query.pageNum = this.currentPage
        this.query.pageSize = this.perPage
        newsList(this.query).then(res => {
          if (res.status) {
            let tempList = [];
            for (let item of res.data) {
              item.isTop = item.isTop == '1' ? true : false
              item.isRed = item.isRed == '1' ? true : false
              item.isView = item.isView == '1' ? true : false
              item.ipLimit = item.ipLimit == '1' ? true : false
              item.isHome = item.isHome == '1' ? true : false
              tempList.push(item);
            }
            this.list = tempList
            this.total = res.total
          }
        });
      },
      searchClick() { //搜索时先将分页的页码归1
        this.currentPage = 1; //当前页				
        this.getList() //渲染数据的函数
      },
      getBigTypeList() {
        bigTypeList().then(res => {
          if (res.status) {
            this.bigTypeList = res.data
          }
        });
      },
      // 考试类别
      getClassListPage() {
        easyList().then(res => {
          if (res.status) {
            this.classList = res.data
          }
        })
      },
      /** 修改状态值 */
      updateStatus(row, type) {
        console.log(row)
        var status = "";
        if (type == 'isTop') {
          status = row.isTop
        } else if (type == 'ipLimit') {
          status = row.ipLimit
        } else if (type == 'isRed') {
          status = row.isRed
        } else if (type == 'isView') {
          status = row.isView
        }

        var params = {
          type: type,
          status: status ? '1' : '0',
          newsId: row.newsId
        }
        changeStatus(params).then((res) => {
          if (res.status) {
            this.$message({
              type: 'success',
              message: "修改成功"
            });
          }
        })
      },
      /** 删除新闻 */
      deleteNews(newsId, text) {
        this.$confirm(`此操作将永久删除该【${text}】的数据, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteNews(newsId).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
            }
            this.getList()
          })
        })
      },
      /** 前往编辑新闻 */
      goToEditNews(newsId) {
        this.$router.push({
          path: '/admin/announcePublish',
          name: 'announcePublish',
          params: {
            newsId: newsId
          }
        })
      },
      handleSizeChange(val) {
        this.perPage = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.currentPage = val
        this.getList();
      },
      getYear() { //获取年份，当前年前后几年
        var y = new Date().getFullYear();
        for (var i = 0; i <= 5; i++) {
          if (i < 5) {
            this.years.unshift({
              value: (y - i),
              label: (y - i)
            })
          } else {
            for (var x = 1; x < 3; x++) {
              this.years.push({
                value: (y + x),
                label: (y + x)
              })
            }
          }
        }
      },
    }
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="pb-3 check-table-top">

              <form class="flexList" style="flex: 1;">
                <div class="operation-text">考试年份：</div>
                <el-select class=" mr-2 blueBorder" style="width:10%;" clearable placeholder="年份" v-model="query.year"
                  size="small">
                  <el-option v-for="(item, i) in years" :label="item.label" :value="item.value" :key="i">
                  </el-option>
                </el-select>
                <div class="operation-text">分类查询：</div>
                <el-select class=" mr-2 blueBorder" style="width: 10%;" placeholder="新闻大类" v-model="query.bigTypeId"
                  size="small">
                  <el-option value="" label="新闻大类"></el-option>
                  <el-option :value="item.bigTypeId" v-for="item in bigTypeList" :key="item.bigTypeId"
                    :label="item.bigTypeName">{{item.bigTypeName}}
                  </el-option>
                </el-select>
                <el-select class=" mr-2 blueBorder" style="width: 10%;" placeholder="新闻大类" v-model="query.lbbm"
                  size="small">
                  <el-option value="" label="考试类别"></el-option>
                  <el-option :value="item.lbbm" v-for="item in classList" :key="item.lbbm" :label="item.lbmc">
                    {{item.lbmc}}
                  </el-option>
                </el-select>
                <!--<button type="button" class="btn btn-info w-md mr-2">查看新闻</button>-->
                <!--<div class="operation-text ">开始序号：</div>-->
                <!--<input type="text" id="simpleinput" class="form-control w-10 h30 mr-2">-->
                <!--<div class="operation-text ">结束序号：</div>-->
                <!--<input type="text" id="simpleinput" class="form-control w-10 h30 mr-2">-->
                <input placeholder="输入关键字" v-model="query.keyword" class="h30 form-control border-blue mr-2 w-15"
                  maxlength="50" />

                <b-button variant="info" class="btn btn-info h30 flexList mr-2" @click="searchClick()"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</b-button>

              </form>



              <div class="d-flex">
                <div class="border-blue export-tab"><i class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>
                <div class="border-blue export-tab"><i class="iconfont icon-data mr-2"></i>dbf</div>
              </div>
            </div>
            <div class="table-responsive border">
              <table class="table light-table table-hover  table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th style="width:3%"> 序号</th>
                    <th>新闻标题</th>
                    <th style="width:13%">新闻类型</th>
                    <th style="width:13%">考试分类</th>
                    <th style="width:5%">浏览量</th>
                    <th style="width:5%">发布人</th>
                    <th style="width:7%">发布时间</th>
                    <th style="width:2%">置顶</th>
                    <th style="width:2%">标红</th>
                    <th style="width:2%">展示</th>
                    <th style="width:2%">限IP</th>
                    <th style="width:3%">编辑</th>
                    <th style="width:3%">删除</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj, index) in list" :key="index">
                    <td>{{index+1+(currentPage-1)*perPage}}</td>
                    <td>
                      <!--<router-link :to="{path:'announcementDetails',query:{newsId:obj.newsId}}">-->
                      <!--{{obj.newsTitle}}-->
                      <!--</router-link>-->
                      <a class="font-blue" target="_blank" :href="'/admin/announcementDetails?newsId=' + obj.newsId">
                        <div>{{ obj.newsTitle }}</div>
                      </a>
                    </td>
                    <td>{{obj.bigType}} </td>
                    <td>{{obj.lbmc}} </td>
                    <td>{{obj.viewCount}}</td>
                    <td>{{obj.createUser}}</td>
                    <td>{{obj.createTime != null ? obj.createTime.substring(0,10) : ""}}</td>
                    <td class="tab-icon">
                      <b-form-checkbox v-model="obj.isTop" @change="updateStatus(obj, 'isTop')" switch
                        class=" switch-check">
                      </b-form-checkbox>
                    </td>
                    <td class="tab-icon">
                      <b-form-checkbox v-model="obj.isRed" @change="updateStatus(obj, 'isRed')" switch
                        class=" switch-check">
                      </b-form-checkbox>
                    </td>
                    <td class="tab-icon">
                      <b-form-checkbox v-model="obj.isView" @change="updateStatus(obj, 'isView')" switch
                        class=" switch-check">
                      </b-form-checkbox>
                    </td>
                    <td class="tab-icon">
                      <b-form-checkbox v-model="obj.ipLimit" @change="updateStatus(obj, 'ipLimit')" switch
                        class=" switch-check">
                      </b-form-checkbox>
                    </td>
                    <td class="tab-icon"><i class="iconfont icon-edit-two" title="修改"
                        @click="goToEditNews(obj.newsId)"></i></td>
                    <td class="tab-icon" @click="deleteNews(obj.newsId,obj.newsTitle)"><i
                        class="iconfont icon-riLine-delete-bin-line" title="删除"></i></td>
                  </tr>
                </tbody>
              </table>

            </div>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="currentPage" :page-size="perPage" layout="total, sizes,jumper, prev, pager, next"
                :total="total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->


    <!-- 弹窗结束 -->
  </Layout>
</template>
